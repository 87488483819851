import React, { useEffect, useState } from "react";
import { scroller } from "react-scroll";
import "./CV2.css";

const CV2 = () => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const cv2Section = document.getElementById("cv2");
      const rect = cv2Section.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (isVisible) {
        const textTimer = setTimeout(() => {
          setShowText(true);
        }, 2000);

        return () => {
          clearTimeout(textTimer);
        };
      }
    };

    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToNextSection = () => {
    scroller.scrollTo("skills", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const scrollToPreviousSection = () => {
    scroller.scrollTo("cv", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <div className="cv2-container wrapper" id="cv2">
      <div className="wrapper__container">
        <div className="wrapper__container-inner jc-adjusted">
          <div className="CV2Blok1">
            <div className="CV2-blok-1">
              <h1 className="headerCV2">OPLEIDINGEN</h1>
              <div className="orange-line"></div>
              <p
                className="boldText"
                style={{ color: "white", padding: "0px" }}
              >
                Meest recente opleidingen en cursussen
              </p>
            </div>
          </div>
          <div className="CV2-blok-2 ">
            <div className={`additional-info ${showText ? "visible" : ""}`}>
              <ul className="opleidingen">
                <li className="opleidingenItem">
                  <span className="blue-stip"></span>React JS - Codecademy
                </li>
                <li className="opleidingenItem">
                  <span className="blue-stip"></span>Project Management - CAPM
                </li>
                <li className="opleidingenItem">
                  <span className="blue-stip"></span>FAST Financial Modeling - F1F9
                </li>
                <li className="opleidingenItem">
                  <span className="blue-stip"></span>Industriële Informatica -
                  Hogeschool PXL
                </li>
              </ul>
            </div>
          </div>

          <div className="CV2-blok-3">
            <div className="navigation-arrows">
              <div
                className="up-arrow"
                onClick={(e) => {
                  e.stopPropagation();
                  scrollToPreviousSection();
                }}
              >
                &#x2191;
              </div>
              <div
                className="down-arrow"
                onClick={(e) => {
                  e.stopPropagation();
                  scrollToNextSection();
                }}
              >
                &#x2193;
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CV2;
