import React from "react";
import { scroller } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import "./Contact.css";

const Contact = () => {
  const scrollToPreviousSection = () => {
    scroller.scrollTo("aboutme", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const handleCall = () => {
    window.location.href = "tel:+32470968413";
  };

  const handleMail = () => {
    window.location.href = "mailto:contact@thymenwillemsen.com";
  };

  const handleAddContact = () => {
    const vcf = `BEGIN:VCARD
VERSION:3.0
FN:Thymen Willemsen
TEL:+32470968413
END:VCARD`;

    // Maak een Blob van de vCard-inhoud
    const blob = new Blob([vcf], { type: "text/vcard" });

    // Maak een tijdelijke downloadlink
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "ThymenWillemsen.vcf";

    // Voeg de link toe aan het document, klik erop en verwijder hem daarna
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Zorg ervoor dat de URL na gebruik wordt vrijgegeven om geheugenlekken te voorkomen
    URL.revokeObjectURL(link.href);
  };

  return (
    <div className="contact-container wrapper" id="contact">
      <div className="wrapper__container">
        <div className="wrapper__container-inner jc-between">
          <div className="contact-blok-1">
            <h1 className="header">CONTACT</h1>
            <div className="orange-line"></div>
          </div>
          <div className="contact-blok-2 wrapper__container-inner jc-between">
            <p>
            Door middel van deze open sollicitatie wil ik mijn interesse kenbaar maken voor een functie als projectmanager of product owner binnen uw bedrijf.
            
            <br />
              Benieuwd naar mijn volledige CV? Graag zou ik mijzelf willen presenteren in een open en persoonlijk
              gesprek. Ik kijk uit naar uw reactie.
            </p>
            <div className="orange-dots-container">
              <span className="orange-dot"></span>
              <span className="orange-dot"></span>
              <span className="orange-dot"></span>
            </div>
            <div>
            <h2>Thymen Willemsen</h2>
            <p style={{fontWeight: 'bold'}}>Kompaniestraat 30
              <br />
            3271 Zichem</p>
            </div>
            <div className="button-container">
              <button onClick={handleCall}>
                <FontAwesomeIcon icon={faPhone} /> Bellen
              </button>
              <button onClick={handleMail}>
                <FontAwesomeIcon icon={faEnvelope} /> Mailen
              </button>
              <button onClick={handleAddContact}>
                <FontAwesomeIcon icon={faUserPlus} /> Contact toevoegen
              </button>
            </div>
          </div>
          <div className="navigation-arrows">
            <div
              className="up-arrow"
              onClick={(e) => {
                e.stopPropagation();
                scrollToPreviousSection();
              }}
            >
              &#x2191;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
