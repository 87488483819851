import React from "react";
import { scroller } from "react-scroll";
import "./Skills.css";




const Skills = ({ showStip2 }) => {
  const scrollToNextSection = () => {
    scroller.scrollTo("aboutme", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const scrollToPreviousSection = () => {
    scroller.scrollTo("cv2", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };



  return (
    <div className="cv-container wrapper" id="skills">
      <div className="wrapper__container">
        <div className="wrapper__container-inner jc-between">
          <div className="skills-blok-1">
            <h1 className="header">SKILLS</h1>
            <div className="orange-line"></div>

          </div>
          <div className="skills-blok-2">
            <div style={{ margin: "0", padding: "0" }}>
              <p id="werkBlok">
                <span className={`stip2 ${showStip2 ? "visible" : ""}`}></span>
              <span className="boldText">Ik zie mijzelf als:</span>
              </p>
              <ul className="werkervaring">
              <li className="werkervaringItem">Een 'outside-the-box' denker</li>
              <li>Communicatief vaardig</li>
              <li>Organisatorisch sterk</li>
              <li>Een teamspeler</li>
              <li>Ondernemend</li>
              <li>Tech-savvy</li>
              <li>Leergierig</li>

                <li>Analytisch</li>
              
              </ul>
            </div>
            {/* <div style={{ margin: "0", padding: "0" }}>
              <p>
                <span className={`stip ${showStip ? "visible" : ""}`}></span>
                Voorgaande jobs:{" "}
                <span className="boldText">Digitale transformatie @</span>
              </p>
              <ul className="werkervaring">
                <li className="werkervaringItem">
                  De tandheelkundige en juwelenindustrie
                </li>
              </ul>
            </div> */}
          </div>
          <div className="skills-blok-3">
            <div className="navigation-arrows">
              <div
                className="up-arrow"
                onClick={(e) => {
                  e.stopPropagation();
                  scrollToPreviousSection();
                }}
              >
                &#x2191;
              </div>
              <div
                className="down-arrow"
                onClick={(e) => {
                  e.stopPropagation();
                  scrollToNextSection();
                }}
              >
                &#x2193;
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );






}; 

export default Skills;
