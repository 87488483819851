import React from "react";
import { scroller } from "react-scroll";
import "./AboutMe.css";

const AboutMe = () => {
  const scrollToNextSection = () => {
    scroller.scrollTo("contact", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const scrollToPreviousSection = () => {
    scroller.scrollTo("skills", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (

<div className="aboutme-container wrapper" id="aboutme">
      <div className="wrapper__container">
        <div className="wrapper__container-inner jc-between">
            <div className="aboutme-blok-1">
              <h1 className="headerAboutme">ABOUT ME</h1>
              <div className="orange-line"></div>

             
              <p
                
                Meest recente opleidingen></p>
                </div>
          
          
          <div className="aboutme-blok-2">
            
          <div >
          <p><span className="boldText" style={{color: 'white'}}>Ik ben:</span>
          </p>
              <ul className="opleidingen">
                <li className="opleidingenItem oranjeTekst">
                  <span className="orange-stip"></span>40 jaar 
                </li>
                <li className="opleidingenItem oranjeTekst">
                  <span className="orange-stip"></span>Geboren in Nederland
                </li>
                
                <li className="opleidingenItem oranjeTekst">
                  <span className="orange-stip"></span>Sinds 2014 woonachtig in België
                </li>
                <li className="opleidingenItem oranjeTekst">
                  <span className="orange-stip"></span>Nederlandstalig en vloeiend in het Engels.
                </li>
                </ul>
                <br/>
                <p><span className="boldText" style={{color: 'white'}}>En houd van:</span>
                </p>
                <ul className="opleidingen">

                
                <li className="opleidingenItem oranjeTekst">
                  <span className="orange-stip2"></span>Reizen
                </li>
                <li className="opleidingenItem oranjeTekst" >
                  <span  className="orange-stip2" ></span>Motor rijden
                </li>
                <li className="opleidingenItem oranjeTekst">
                  <span className="orange-stip2"></span>Alles wat creatief of technisch is
                </li>
                
              </ul>
            </div>
            
          </div>

          <div className="aboutme-blok-3">
            <div className="navigation-arrows">
              <div
                className="up-arrow"
                onClick={(e) => {
                  e.stopPropagation();
                  scrollToPreviousSection();
                }}
              >
                &#x2191;
              </div>
              <div
                className="down-arrow"
                onClick={(e) => {
                  e.stopPropagation();
                  scrollToNextSection();
                }}
              >
                &#x2193;
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>

  );
};

export default AboutMe;
